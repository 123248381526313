import React from "react";
import { Home } from "./components/Home";
import { About } from "./components/About";
import { Blog } from "./components/Blog";
import { Header, Footer } from "./components";
import { useCookies } from "react-cookie";
import { useEffect, useState } from "react";
import Loading from "./components/Loading";
import useGeoLocation from "react-ipgeolocation";
import { Privacy } from "./components/Privacy";

import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  Outlet,
  RouterProvider,
} from "react-router-dom";

const App = () => {
  const [cookies, setCookie] = useCookies(["cookie-name"]);

  const [themeToggling, setThemeToggling] = useState();
  const [eng, setEng] = useState();

  const location = useGeoLocation();
  console.log(location.country);

  useEffect(() => {
    if (cookies.langEng === undefined || cookies.themeDark === undefined) {
      setCookie("langEng", "false");
      setCookie("themeDark", "false");
      setEng(false);
      setThemeToggling(false);
    } else {
      setEng(JSON.parse(cookies.langEng));
      setThemeToggling(JSON.parse(cookies.themeDark));
    }
  }, [setCookie, cookies.langEng, cookies.themeDark, setEng, setThemeToggling]);

  useEffect(
    () =>
      themeToggling
        ? window.document.body.classList.add("dark")
        : window.document.body.classList.remove("dark"),
    [themeToggling]
  );

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route
        path="/"
        element={
          <Root
            themeToggling={themeToggling}
            eng={eng}
            setThemeToggling={setThemeToggling}
            setEng={setEng}
            cookies={cookies}
            setCookie={setCookie}
          />
        }
      >
        <Route
          index
          element={<Home themeToggling={themeToggling} eng={eng} />}
        />
        <Route
          path="/about"
          element={<About themeToggling={themeToggling} eng={eng} />}
        />
        <Route
          path="blog"
          element={<Blog themeToggling={themeToggling} eng={eng} />}
        />
        <Route
          path="privacy"
          element={<Privacy themeToggling={themeToggling} eng={eng} />}
        />
      </Route>
    )
  );

  return (
    <div className="font-ubuntu">
      <RouterProvider router={router} />
    </div>
  );
};

const Root = ({
  themeToggling,
  setThemeToggling,
  eng,
  setEng,
  setLangToggler,
  cookies,
  setCookie,
}) => {
  const [spinner, setSpinner] = useState(true);

  useEffect(() => {
    setTimeout(() => setSpinner(false), 1500);
  }, []);

  if (spinner === true) {
    return (
      <div className="h-screen w-screen scale-150 dark:bg-[#2B1867]">
        <Loading />
      </div>
    );
  } else {
    return (
      <>
        {/* <div>
        <Link to={"/"}>Home</Link>
        <Link to={"/about"}>About</Link>
        <Link to={"/blog"}>Blog</Link>
      </div> */}
        <div>
          <Header
            themeToggling={themeToggling}
            setThemeToggling={setThemeToggling}
            eng={eng}
            setEng={setEng}
            setLangToggler={setLangToggler}
            cookies={cookies}
            setCookie={setCookie}
          />
          <Outlet />
          <Footer eng={eng} />
        </div>
      </>
    );
  }
};

export default App;
