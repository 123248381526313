import images from "./images";

export const appRelatedInfo = [
  {
    id: 1,
    img: images.Team,
    title: "100+ Customers",
    info: "We bring your ideas for websites, web applications, products, tools, and dashboards to life.",
  },
  {
    id: 2,
    img: images.Rating,
    title: "Positive Reviews",
    info: "Customers love working with us. Ongoing support, frequent updates and powerful features that we deliver, make us their solid choice",
  },
  {
    id: 3,
    img: images.Apps,
    title: "Full Stack",
    info: "When we've agreed on what we're going to build, we begin developing the website by writing the code that makes it real, using modern technologies.",
  },
];

export const appRelatedInfoFa = [
  {
    id: 3,
    img: images.Apps,
    title: "استک به‌روز",
    info: "پس از توافق بر روی طرح وبسایت، توسعه دهندگان ما توسط به‌روز ترین روش‌های برنامه‌نویسی وب، به ایده شما جان می‌بخشند",
  },
  {
    id: 2,
    img: images.Rating,
    title: "بازخورد عالی",
    info: "ما و مشتریانمان از همکاری با یکدیگر لذت می‌بریم. پشتیبانی دائمی، به روز رسانی‌های پیاپی و قابلیت‌های اضافه شده به سایت باعث شده آن‌ها ما را انتخاب کنند",
  },
  {
    id: 1,
    img: images.Team,
    title: "بیش از ۱۰۰ مشتری",
    info: "طرح دلخواه شما توسط تیم مجرب برنامه‌نویسی و طراحی وب مجموعه ما پیاده سازی می‌شود",
  },
];

export const infoData = [
  {
    id: 1,
    img: images.ImageArt,
    title: "Steller Assets & NFTs",
    info: "Trade your Steller assets anytime & anywhere Choose how you want to sell your NFTS and we'll help you on that & get 24/7 support from us",
  },
  {
    id: 2,
    img: images.CoinDollar,
    title: "Low Commissions",
    info: "Most competitive fees in the industry. users can invest, trade or withdraw funds without worrying about fees and commissions",
  },
  {
    id: 3,
    img: images.ShieldOk,
    title: "Data Security",
    info: "Security of user information and funds is our first  first priority. StellerLedger is the only decentralized exchange without scam or duplicate tokens",
  },
];

export const coinInfo = [
  {
    id: 1,
    coinImg: images.BitCoin,
    coinName: "Bitcoin",
    amount: "4,691.198",
    highTime: "24H High",
    highTimeAmount: "42,174.00",
    lowTime: "24H High",
    lowTimeAmount: "42,174.00",
    trendImg: images.TrendUp,
    currency1: "BTC",
    currency2: "AUD",
  },
  {
    id: 2,
    coinImg: images.Ethereum,
    coinName: "Ethereum",
    amount: "4,691.198",
    highTime: "24H High",
    highTimeAmount: "42,174.00",
    lowTime: "24H High",
    lowTimeAmount: "42,174.00",
    trendImg: images.TrendDown,
    currency1: "ETH",
    currency2: "AUD",
  },
  {
    id: 3,
    coinImg: images.LiteCoin,
    coinName: "Litecoin",
    amount: "4,691.198",
    highTime: "24H High",
    highTimeAmount: "42,174.00",
    lowTime: "24H High",
    lowTimeAmount: "42,174.00",
    trendImg: images.TrendUp,
    currency1: "LTC",
    currency2: "AUD",
  },
  {
    id: 4,
    coinImg: images.BitCoin,
    coinName: "Bitcoin",
    amount: "4,691.198",
    highTime: "24H High",
    highTimeAmount: "42,174.00",
    lowTime: "24H High",
    lowTimeAmount: "42,174.00",
    trendImg: images.TrendUp,
    currency1: "BTC",
    currency2: "AUD",
  },
  {
    id: 5,
    coinImg: images.Steller,
    coinName: "Steller",
    amount: "4,691.198",
    highTime: "24H High",
    highTimeAmount: "42,174.00",
    lowTime: "24H High",
    lowTimeAmount: "42,174.00",
    trendImg: images.TrendDown,
    currency1: "XLM",
    currency2: "AUD",
  },
];

export const companyLogo = [
  {
    id: 1,
    darkImg: images.a_MoneyGram,
    lightImg: images.a_MoneyGram_W,
  },
  {
    id: 2,
    darkImg: images.b_SatoshiPay,
    lightImg: images.b_SatoshiPay_W,
  },
  {
    id: 3,
    darkImg: images.c_Circle,
    lightImg: images.c_Circle_W,
  },
  {
    id: 4,
    darkImg: images.d_ClickPesa,
    lightImg: images.d_ClickPesa_W,
  },
  {
    id: 5,
    darkImg: images.a_MoneyGram,
    lightImg: images.a_MoneyGram_W,
  },
  {
    id: 6,
    darkImg: images.b_SatoshiPay,
    lightImg: images.b_SatoshiPay_W,
  },
  {
    id: 7,
    darkImg: images.c_Circle,
    lightImg: images.c_Circle_W,
  },
  {
    id: 8,
    darkImg: images.d_ClickPesa,
    lightImg: images.d_ClickPesa_W,
  },
];
