import googlePlayLogo from "../assets/googlePlayLogo.png";
import Construction from "../assets/404.png";
import HeroGraphWhite from "../assets/HeroGraphWhite.png";
import HeroGraphBlack from "../assets/HeroGraphBlack.png";
import VideoBanner from "../assets/VideoBanner.png";
import FooterGPlay from "../assets/FooterGPlay.png";
import FooterApple from "../assets/FooterApple.png";
import CoinDollar from "../assets/CoinDollar.png";
import HeroMobile from "../assets/HeroMobile.png";
import HeroMobileW from "../assets/HeroMobileW.png";
import HeroPeople from "../assets/HeroPeople.png";
import HeroRating from "../assets/HeroRating.png";
import appleLogo from "../assets/appleLogo.png";
import HeroApple from "../assets/HeroApple.png";
import MobileArt from "../assets/MobileArt.png";
import Bitcoin1 from "../assets/Bitcoin-1.png";
import Bitcoin2 from "../assets/Bitcoin-2.png";
import ImageArt from "../assets/ImageArt.png";
import ShieldOk from "../assets/ShieldOk.png";
import Ethereum from "../assets/Ethereum.png";
import LiteCoin from "../assets/LiteCoin.png";
import USA_Logo from "../assets/USA_Logo.png";
import IRN_Logo from "../assets/IRN_Logo.png";
import BitCoin from "../assets/BitCoin.png";
import Steller from "../assets/Steller.png";
import Rating from "../assets/Rating.png";
import Apps from "../assets/Apps.png";
import Team from "../assets/Team.png";
import Logo from "../assets/Logo.png";
import LogoW from "../assets/LogoW.png";
import topLeft_1 from "../assets/1_topLeft.png";
import topLeft_1_w from "../assets/1_topLeftW.png";
import topLeft_2 from "../assets/2_topLeft.png";
import topLeft_2_w from "../assets/2_topLeftW.png";
import bottomLeft_3 from "../assets/3_bottomLeft.png";
import bottomLeft_3_w from "../assets/3_bottomLeftW.png";
import topRight_4 from "../assets/4_topRight.png";
import topRight_4_w from "../assets/4_topRightW.png";
import bottomRight_5 from "../assets/5_bottomRight.png";
import bottomRight_5_w from "../assets/5_bottomRightW.png";
import bottomRightSmall_6 from "../assets/6_bottomRightSmall.png";
import bottomRightSmall_6_w from "../assets/6_bottomRightSmallW.png";
import a_MoneyGram from "../assets/a_MoneyGram.png";
import a_MoneyGram_W from "../assets/a_MoneyGram_W.png";
import b_SatoshiPay from "../assets/b_SatoshiPay.png";
import b_SatoshiPay_W from "../assets/b_SatoshiPay_W.png";
import c_Circle from "../assets/c_Circle.png";
import c_Circle_W from "../assets/c_Circle_W.png";
import d_ClickPesa from "../assets/d_ClickPesa.png";
import d_ClickPesa_W from "../assets/d_ClickPesa_W.png";
import TrendDown from "../assets/TrendDown.png";
import TrendUp from "../assets/TrendUp.png";
import menuBar from "../assets/menuBar.png";

const images = {
  googlePlayLogo,
  Construction,
  HeroGraphBlack,
  HeroGraphWhite,
  VideoBanner,
  FooterGPlay,
  FooterApple,
  HeroMobile,
  HeroMobileW,
  HeroPeople,
  HeroRating,
  CoinDollar,
  appleLogo,
  HeroApple,
  MobileArt,
  ImageArt,
  ShieldOk,
  Bitcoin1,
  Bitcoin2,
  Ethereum,
  LiteCoin,
  USA_Logo,
  IRN_Logo,
  BitCoin,
  Steller,
  Rating,
  Logo,
  LogoW,
  Apps,
  Team,
  topLeft_1,
  topLeft_1_w,
  topLeft_2,
  topLeft_2_w,
  bottomLeft_3,
  bottomLeft_3_w,
  topRight_4,
  topRight_4_w,
  bottomRight_5,
  bottomRight_5_w,
  bottomRightSmall_6,
  bottomRightSmall_6_w,
  a_MoneyGram,
  a_MoneyGram_W,
  b_SatoshiPay,
  b_SatoshiPay_W,
  c_Circle_W,
  c_Circle,
  d_ClickPesa,
  d_ClickPesa_W,
  TrendDown,
  TrendUp,
  menuBar,
};

export default images;
