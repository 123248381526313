import { Helmet } from "react-helmet";
import { HeroSection, ImgInfoVideo } from "../components";
import BackToUp from "@uiw/react-back-to-top";
import { FaArrowUp } from "react-icons/fa";
import { ConsentBanner } from "react-hook-consent";
import "react-hook-consent/dist/styles/style.css";

// import { CoinSlider, CompanySlider, IntroAbout } from "../components";

export const Home = ({ themeToggling, eng }) => {
  return (
    <>
      <Helmet>
        <title>Dara Raya Tech | Home</title>
        <meta
          name="We design & deploy your Website."
          content="Dara Raya Tech"
        />
      </Helmet>
      <main className="overflow-hidden">
        <HeroSection themeToggling={themeToggling} eng={eng} />
        {/* <IntroAbout /> */}
        {/* <CoinSlider /> */}
        <ImgInfoVideo eng={eng} />
        {/* <CompanySlider themeToggling={themeToggling} /> */}

        {eng ? (
          <div className="font-ubuntu">
            <ConsentBanner
              settings={{
                hidden: false,
                label: "More",
                modal: { title: "Cookie Consent Configuration" },
              }}
              decline={{ show: true, label: "No" }}
              approve={{ label: "Yes" }}
            >
              <div className="w-5/6">
                We use cookies to personalize our website to your interests and
                for analytics purposes. By using our <a href="test">website</a>,
                you agree to our use of cookies.
              </div>
            </ConsentBanner>
          </div>
        ) : (
          <div className="font-fa flex w-max flex-auto ">
            <ConsentBanner
              settings={{
                hidden: false,
                label: "بیشتر",
                modal: { title: "توضیحات تأئید کوکی ها" },
              }}
              decline={{ show: true, label: "رد" }}
              approve={{ label: "تأئید" }}
            >
              <div className="font-fa text-right w-5/6">
                برای تجربه بهتر شما و بهبود رابط کاربری، سایت ما از کوکی استفاده
                می کند. استفاده از<a href="test"> وبسایت ما </a>به منزله تأئید
                استفاده از کوکی می باشد
              </div>
            </ConsentBanner>
          </div>
        )}
        <BackToUp>
          <FaArrowUp />
        </BackToUp>
      </main>
    </>
  );
};
