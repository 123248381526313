import images from "../constants/images";
import Circles from "./Circles";

const HeroSection = ({ themeToggling, eng }) => {
  return (
    <section className="px-4 lg:px-36 py-12 text-center dark:bg-[#200A4C] text-gray-700 dark:text-gray-200 relative pointer-events-none">
      <div className="absolute top-0 left-0 right-0 bottom-0  h-full w-full dark:bg-[#2B1867] lightRound xl:lightRoundBig"></div>

      <Circles themeToggling={themeToggling} />

      {eng ? (
        <h1 className="relative font-bold text-[28px] pt-4 md:text-4xl lg:text-5xl lg:leading-[60px]">
          We design & deploy <br /> your Website
        </h1>
      ) : (
        <h1 className="relative font-bold text-[28px] pt-4 md:text-4xl lg:text-5xl lg:leading-[60px] font-fa">
          طراحی و پیاده‌سازی وب‌سایت خود را به <br /> ما بسپارید
        </h1>
      )}

      {eng ? (
        <p className="relative pt-8 w-full lg:w-[650px] mx-auto ">
          Our professional UI/UX designers & dexterous web developers streamline
          the path of having an ideal website
        </p>
      ) : (
        <p className="relative pt-8 w-full lg:w-[650px] mx-auto font-fa ">
          طراحان خوش ذوق و توسعه دهنگان حرفه‌ای وب مجموعه ما، راه رسیدن به یک
          وب‌سایت ایده آل و داینامیک را برای شما هموار می‌کنند
        </p>
      )}

      {/* <div className="relative flex items-center justify-center py-10 gap-4 text-slate-200">
        <div className="flex items-center gap-4 px-4 py-2 rounded-lg bg-gradient-to-r from-[#BF36E6] to-blue-800 cursor-pointer hover:shadow-xl duration-200 ">
          <img src={images.appleLogo} alt="" className="w-5" />
          <div className="text-left">
            <p className="text-xs ">Download on</p>
            <p className="">Apple store</p>
          </div>
        </div>

        <div className="flex items-center gap-4 px-4 py-2 rounded-lg bg-[#38217F] cursor-pointer hover:shadow-xl duration-200">
          <img src={images.googlePlayLogo} alt="" className="w-5" />
          <div className="text-left">
            <p className="text-xs ">Get it on</p>
            <p className="">Google Play</p>
          </div>
        </div>
      </div> */}

      {/* Mobile Image UI Section */}
      <div className="relative flex items-center justify-center">
        {themeToggling ? (
          <img src={images.HeroMobileW} alt="" className="w-d w-2/5" />
        ) : (
          <img src={images.HeroMobile} alt="" className="w-d w-2/5" />
        )}
        {/* Ratting Image */}
        <img
          src={images.HeroRating}
          alt=""
          className="w-44 absolute top-[72px] lg:top-20 right-4 lg:right-72 3xl:right-[505px]"
        />

        {/* Peoples Image */}
        {/* <img
          src={images.HeroPeople}
          alt=""
          className="w-48 absolute bottom-14 lg:bottom-28 left-4 lg:left-72 lg:scale-125 3xl:left-[470px]"
        /> */}
      </div>
    </section>
  );
};

export default HeroSection;
