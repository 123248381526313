import React from "react";
import images from "../constants/images";
import Graph from "../assets/Graph";

const Circles = ({ themeToggling }) => {
  return (
    <div>
      <div className="absolute top-0 left-0 right-0 bottom-0  h-full w-full dark:bg-[#2B1867] lightRound xl:lightRoundBig"></div>
      {/* 🟡 Circle 1 🟡*/}
      {/* <div className={`w-36 h-36 bg-gradient-to-l ${themeToggling ? 'from-indigo-900' : 'from-slate-200'} rounded-full absolute top-[-180px] left-[370px]`}></div> */}
      {/* <div
        className={`hidden lg:block absolute top-[-160px] left-[350px] 3xl:left-[440px] w-40 -z-0`}
      >
        <img
          src={themeToggling ? images.topLeft_1 : images.topLeft_1_w}
          alt=""
          className="w-full"
        />
      </div> */}
      {/* 🟡 Circle 2 🟡*/}
      {/* <div className={`w-80 h-80 bg-gradient-to-l ${themeToggling
          ? 'from-[#2B1867] to-[#BF36E6] opacity-10 rotate-[210deg]'
          : 'from-slate-200 rotate-[30deg]'} 
          rounded-full absolute top-40 left-0`}></div> */}
      <div
        className={`hidden lg:block absolute top-20 left-10 3xl:left-52 w-[340px] -z-0`}
      >
        <img
          src={themeToggling ? images.topLeft_2 : images.topLeft_2_w}
          alt=""
          className="w-full"
        />
      </div>
      {/* 🟡 Circle 3 🟡*/}
      <div
        className={`hidden lg:block absolute bottom-0 left-[-220px] w-96 -z-0`}
      >
        <img
          src={themeToggling ? images.bottomLeft_3 : images.bottomLeft_3_w}
          alt=""
          className="w-full"
        />
      </div>
      {/* 🟡 Circle 4 🟡*/}
      <div
        className={`hidden lg:block absolute top-0 lg:top-16 right-36 w-48 -z-0`}
      >
        <img
          src={themeToggling ? images.topRight_4 : images.topRight_4_w}
          alt=""
          className="w-full"
        />
      </div>
      {/* 🟡 Circle 5 🟡*/}
      <div
        className={`hidden lg:block absolute bottom-0 right-[-120px] w-72 -z-0 `}
      >
        <img
          src={themeToggling ? images.bottomRight_5 : images.bottomRight_5_w}
          alt=""
          className="w-full"
        />
      </div>
      {/* 🟡 Circle 6 🟡*/}
      <div
        className={`hidden lg:block absolute bottom-[90px] 3xl:bottom-24 right-[430px] 3xl:right-[610px] w-32 -z-0`}
      >
        <img
          src={
            themeToggling
              ? images.bottomRightSmall_6
              : images.bottomRightSmall_6_w
          }
          alt=""
          className="w-full"
        />
      </div>
      {/* 🟡 UI For ==> Graph 🟡*/}
      <div
        className={`absolute top-40 lg:top-[-40px] left-[50%] translate-x-[-50%] w-full lg:w-[780px]`}
      >
        {themeToggling ? (
          <Graph color={`#1F307D`} className={`w-full`} />
        ) : (
          <Graph color={`#E6E6E6`} className={`opacity-30 w-full`} />
        )}
      </div>
    </div>
  );
};

export default Circles;
