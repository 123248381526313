import React from "react";
import { Helmet } from "react-helmet";
import BackToUp from "@uiw/react-back-to-top";
import { FaArrowUp } from "react-icons/fa";
// import images from "../constants/images";
import Circles from "./Circles";

export const Privacy = ({ themeToggling, eng }) => {
  return (
    <>
      <Helmet>
        <title>Dara Raya Tech | Privacy</title>
        <meta
          name="We design & deploy your Website."
          content="Dara Raya Tech"
        />
      </Helmet>
      <section className="px-4 lg:px-36 py-12 text-center dark:bg-[#200A4C] text-gray-700 dark:text-gray-200 relative pointer-events-none">
        <div className="absolute top-0 left-0 right-0 bottom-0  h-full w-full dark:bg-[#2B1867] lightRound xl:lightRoundBig"></div>

        <Circles themeToggling={themeToggling} />

        <div className="lg:flex font-fa">
          {eng ? (
            <h1 className="relative font-bold text-[28px] lg:pt-24 lg:pl-24 md:text-4xl lg:text-5xl lg:leading-[60px]">
              Privacy Policy
            </h1>
          ) : (
            <h1 className="relative font-bold text-[28px] lg:pt-24 lg:pl-24 md:text-4xl lg:text-5xl lg:leading-[60px]">
              حریم خصوصی
            </h1>
          )}

          {eng ? (
            <p className="relative pt-8 w-full lg:w-[650px] mx-auto text-justify">
              <p className="pb-4">
                We respect your privacy and are committed to protecting it
                through our compliance with this privacy policy (“Policy”). This
                Policy describes the types of information we may collect from
                you or that you may provide (“Personal Information”) on the
                drtco.ir website (“Website” or “Service”) and any of its related
                products and services (collectively, “Services”), and our
                practices for collecting, using, maintaining, protecting, and
                disclosing that Personal Information. It also describes the
                choices available to you regarding our use of your Personal
                Information and how you can access and update it. This Policy is
                a legally binding agreement between you (“User”, “you” or
                “your”) and Dara Raya Tech Co. Ltd. (doing business as “DRT
                Co.”, “we”, “us” or “our”). If you are entering into this Policy
                on behalf of a business or other legal entity, you represent
                that you have the authority to bind such entity to this Policy,
                in which case the terms “User”, “you” or “your” shall refer to
                such entity. If you do not have such authority, or if you do not
                agree with the terms of this Policy, you must not accept this
                Policy and may not access and use the Website and Services. By
                accessing and using the Website and Services, you acknowledge
                that you have read, understood, and agree to be bound by the
                terms of this Policy. This Policy does not apply to the
                practices of companies that we do not own or control, or to
                individuals that we do not employ or manage. Collection of
                personal information You can access and use the Website and
                Services without telling us who you are or revealing any
                information by which someone could identify you as a specific,
                identifiable individual. If, however, you wish to use some of
                the features offered on the Website, you may be asked to provide
                certain Personal Information (for example, your name and e-mail
                address). We receive and store any information you knowingly
                provide to us when you create an account, or fill any forms on
                the Website. When required, this information may include the
                following: - Account details (such as user name, unique user ID,
                password, etc) - Basic personal information (such as name,
                country of residence, etc) You can choose not to provide us with
                your Personal Information, but then you may not be able to take
                advantage of some of the features on the Website. Users who are
                uncertain about what information is mandatory are welcome to
                contact us. Privacy of children We do not knowingly collect any
                Personal Information from children under the age of 18. If you
                are under the age of 18, please do not submit any Personal
                Information through the Website and Services. If you have reason
                to believe that a child under the age of 18 has provided
                Personal Information to us through the Website and Services,
                please contact us to request that we delete that child’s
                Personal Information from our Services. We encourage parents and
                legal guardians to monitor their children’s Internet usage and
                to help enforce this Policy by instructing their children never
                to provide Personal Information through the Website and Services
                without their permission. We also ask that all parents and legal
                guardians overseeing the care of children take the necessary
                precautions to ensure that their children are instructed to
                never give out Personal Information when online without their
                permission. Use and processing of collected information We act
                as a data controller and a data processor when handling Personal
                Information, unless we have entered into a data processing
                agreement with you in which case you would be the data
                controller and we would be the data processor. Our role may also
                differ depending on the specific situation involving Personal
                Information. We act in the capacity of a data controller when we
                ask you to submit your Personal Information that is necessary to
                ensure your access and use of the Website and Services. In such
                instances, we are a data controller because we determine the
                purposes and means of the processing of Personal Information. We
                act in the capacity of a data processor in situations when you
                submit Personal Information through the Website and Services. We
                do not own, control, or make decisions about the submitted
                Personal Information, and such Personal Information is processed
                only in accordance with your instructions. In such instances,
                the User providing Personal Information acts as a data
                controller. In order to make the Website and Services available
                to you, or to meet a legal obligation, we may need to collect
                and use certain Personal Information. If you do not provide the
                information that we request, we may not be able to provide you
                with the requested products or services. Any of the information
                we collect from you may be used for the following purposes: -
                Create and manage user accounts - Run and operate the Website
                and Services Processing your Personal Information depends on how
                you interact with the Website and Services, where you are
                located in the world and if one of the following applies: (i)
                you have given your consent for one or more specific purposes;
                (ii) provision of information is necessary for the performance
                of this Policy with you and/or for any pre-contractual
                obligations thereof; (iii) processing is necessary for
                compliance with a legal obligation to which you are subject;
                (iv) processing is related to a task that is carried out in the
                public interest or in the exercise of official authority vested
                in us; (v) processing is necessary for the purposes of the
                legitimate interests pursued by us or by a third party. Note
                that under some legislations we may be allowed to process
                information until you object to such processing by opting out,
                without having to rely on consent or any other of the legal
                bases. In any case, we will be happy to clarify the specific
                legal basis that applies to the processing, and in particular
                whether the provision of Personal Information is a statutory or
                contractual requirement, or a requirement necessary to enter
                into a contract. Disclosure of information Depending on the
                requested Services or as necessary to complete any transaction
                or provide any Service you have requested, we may share your
                information with our affiliates, contracted companies, and
                service providers (collectively, “Service Providers”) we rely
                upon to assist in the operation of the Website and Services
                available to you and whose privacy policies are consistent with
                ours or who agree to abide by our policies with respect to
                Personal Information. We will not share any personally
                identifiable information with third parties and will not share
                any information with unaffiliated third parties. Service
                Providers are not authorized to use or disclose your information
                except as necessary to perform services on our behalf or comply
                with legal requirements. Service Providers are given the
                information they need only in order to perform their designated
                functions, and we do not authorize them to use or disclose any
                of the provided information for their own marketing or other
                purposes. Retention of information We will retain and use your
                Personal Information for the period necessary as long as your
                user account remains active, to enforce our Policy, resolve
                disputes, and unless a longer retention period is required or
                permitted by law. We may use any aggregated data derived from or
                incorporating your Personal Information after you update or
                delete it, but not in a manner that would identify you
                personally. Once the retention period expires, Personal
                Information shall be deleted. Therefore, the right to access,
                the right to erasure, the right to rectification, and the right
                to data portability cannot be enforced after the expiration of
                the retention period. Cookies Our Website and Services use
                “cookies” to help personalize your online experience. A cookie
                is a text file that is placed on your hard disk by a web page
                server. Cookies cannot be used to run programs or deliver
                viruses to your computer. Cookies are uniquely assigned to you,
                and can only be read by a web server in the domain that issued
                the cookie to you. We may use cookies to collect, store, and
                track information for security and personalization, and for
                statistical purposes. Please note that you have the ability to
                accept or decline cookies. Most web browsers automatically
                accept cookies by default, but you can modify your browser
                settings to decline cookies if you prefer. Data analytics Our
                Website and Services may use third-party analytics tools that
                use cookies, web beacons, or other similar information-gathering
                technologies to collect standard internet activity and usage
                information. The information gathered is used to compile
                statistical reports on User activity such as how often Users
                visit our Website and Services, what pages they visit and for
                how long, etc. We use the information obtained from these
                analytics tools to monitor the performance and improve our
                Website and Services. Do Not Track signals Some browsers
                incorporate a Do Not Track feature that signals to websites you
                visit that you do not want to have your online activity tracked.
                Tracking is not the same as using or collecting information in
                connection with a website. For these purposes, tracking refers
                to collecting personally identifiable information from consumers
                who use or visit a website or online service as they move across
                different websites over time. The Website and Services do not
                track its visitors over time and across third-party websites.
                However, some third-party websites may keep track of your
                browsing activities when they serve you content, which enables
                them to tailor what they present to you. For a description of Do
                Not Track protocols for browsers and mobile devices or to learn
                more about the choices available to you, visit
                internetcookies.com Links to other resources The Website and
                Services contain links to other resources that are not owned or
                controlled by us. Please be aware that we are not responsible
                for the privacy practices of such other resources or third
                parties. We encourage you to be aware when you leave the Website
                and Services and to read the privacy statements of each and
                every resource that may collect Personal Information.
                Information security We secure information you provide on
                computer servers in a controlled, secure environment, protected
                from unauthorized access, use, or disclosure. We maintain
                reasonable administrative, technical, and physical safeguards in
                an effort to protect against unauthorized access, use,
                modification, and disclosure of Personal Information in our
                control and custody. However, no data transmission over the
                Internet or wireless network can be guaranteed. Therefore, while
                we strive to protect your Personal Information, you acknowledge
                that (i) there are security and privacy limitations of the
                Internet which are beyond our control; (ii) the security,
                integrity, and privacy of any and all information and data
                exchanged between you and the Website and Services cannot be
                guaranteed; and (iii) any such information and data may be
                viewed or tampered with in transit by a third party, despite
                best efforts. As the security of Personal Information depends in
                part on the security of the device you use to communicate with
                us and the security you use to protect your credentials, please
                take appropriate measures to protect this information. Data
                breach In the event we become aware that the security of the
                Website and Services has been compromised or Users’ Personal
                Information has been disclosed to unrelated third parties as a
                result of external activity, including, but not limited to,
                security attacks or fraud, we reserve the right to take
                reasonably appropriate measures, including, but not limited to,
                investigation and reporting, as well as notification to and
                cooperation with law enforcement authorities. In the event of a
                data breach, we will make reasonable efforts to notify affected
                individuals if we believe that there is a reasonable risk of
                harm to the User as a result of the breach or if notice is
                otherwise required by law. When we do, we will send you an
                email. Changes and amendments We reserve the right to modify
                this Policy or its terms related to the Website and Services at
                any time at our discretion. When we do, we will send you an
                email to notify you. We may also provide notice to you in other
                ways at our discretion, such as through the contact information
                you have provided. An updated version of this Policy will be
                effective immediately upon the posting of the revised Policy
                unless otherwise specified. Your continued use of the Website
                and Services after the effective date of the revised Policy (or
                such other act specified at that time) will constitute your
                consent to those changes. However, we will not, without your
                consent, use your Personal Information in a manner materially
                different than what was stated at the time your Personal
                Information was collected. Acceptance of this policy You
                acknowledge that you have read this Policy and agree to all its
                terms and conditions. By accessing and using the Website and
                Services and submitting your information you agree to be bound
                by this Policy. If you do not agree to abide by the terms of
                this Policy, you are not authorized to access or use the Website
                and Services.
              </p>
            </p>
          ) : (
            <p className="relative pt-8 w-full lg:w-[650px] mx-auto text-right text-lg">
              <p className="pb-4">
                دارا رایا تک چگونه از اطلاعات شما حراست می‌کند؟ امروزه با گسترش
                جریان اطلاعات و استفاده روزمره کاربران از اینترنت و وسایل
                ارتباطی، حریم خصوصی کاربران به یکی از جدی‌ترین دغدغه‌ها تبدیل
                شده‌است. نگرانی بسیاری از مشتریان، محفوظ ماندن اطلاعات شخصی
                همچنین شماره موبایل، نام و نام خانوادگی و ایمیل است که برای ثبت
                نام و لاگین شدن به ما می‌سپارند. باید گفت ما نیز اهمیت این موضوع
                را درک می‌کنیم و حریم خصوصی کاربران برای ما اهمیت بسیاری دارد.
                در ذیل سیاست حفظ حریم خصوصی و محرمانگی اطلاعات کاربران را ملاحظه
                خواهیدکرد. چه اطلاعاتی از شما دریافت می‌شود؟ برای ثبت نام در
                اپلیکشین و وب سایت دارا رایا تک لازم است تا اطلاعاتی مانند نام و
                نام خانوادگی و ایمیل خود را در اختیار ما قرار دهید. دریافت
                مشخصات فردی شما نیز صرفاً برای ثبت نام در سایت، ارسال اعلان‌های
                مهم و باخبر کردن شما از رویدادهای ویژه، استفاده از خدمات گوناگون
                شرکت دارا رایا تک است. چگونه از اطلاعات شما محافظت می‌شود؟ با
                وجود این‌که امنیت را به صورت نسبی تعریف شده است اما در طراحی
                اپلیکیشن و وب سایت شرکت دارا رایا تک سعی شده که از بالاترین سطوح
                امنیتی سخت‌افزاری و نرم‌افزاری در نظر گرفته شود. اطلاعات شخصی
                شما نیز به صورت رمزنگاری‌شده در بستر امن به وب‌سایت ما منتقل و
                برای تعاملات بعدی نگهداری خواهند شد. چه استفاده‌ای اطلاعات شما
                خواهد شد؟ چه از نظر قانونی چه از نظر اخلاقی ما موظف به صیانت از
                اطلاعات و پرونده‌های شما هستیم. اطلاعات شخصی شما نیز صرفاً جهت
                تعاملات بعدی به صورت کدگذاری‌شده نگهداری می‌شوند تا در سفارش‌ها
                و درخواست‌های آینده بتوانید به راحتی وارد سامانه شوید و سفارش
                خود را ثبت نمایید. ما هم از اسپم متنفریم و ایمیل‌ها و پیام‌های
                تبلیغاتی را برای کاربران‌مان ارسال نخواهیم کرد
              </p>
            </p>
          )}
        </div>
      </section>
      <BackToUp>
        <FaArrowUp />
      </BackToUp>
    </>
  );
};
