import { useState } from "react";
import MenuBar from "../assets/MenuBar";
import images from "../constants/images";
import { Link } from "react-router-dom";
import Login from "./Login";

const Header = ({
  themeToggling,
  setThemeToggling,
  eng,
  setEng,
  setCookie,
}) => {
  const [menuToggling, setMenuToggling] = useState(false);

  const handleLang = () => {
    setEng(!eng);
    setCookie("langEng", !eng);
  };

  const handleMenu = () => {
    setMenuToggling((pre) => !pre);
  };

  const handleTheme = () => {
    setThemeToggling((pre) => !pre);
    setCookie("themeDark", !themeToggling);
  };

  return (
    <header className="px-4 lg:px-36 py-6 flex items-center justify-between flex-wrap  dark:bg-[#2B1867] text-gray-700 dark:text-gray-200 z-30">
      {/* 🟨🟨🟨 UI For ==> Logo */}
      <div className="flex items-center gap-4 justify-center z-50 lg:w-3/12 pointer-events-none">
        {themeToggling ? (
          <img src={images.LogoW} alt="" className="w-12 " />
        ) : (
          <img src={images.Logo} alt="" className="w-12 " />
        )}

        {eng ? (
          <div className="lg:w-2/3">
            <h1 className="text-xl md:text-2xl font-bold">Dara Raya Tech</h1>
            <p className="text-xs md:text-sm ">Software Company</p>
          </div>
        ) : (
          <div className="lg:w-2/3 font-fa flex">
            <div className="text-right">
              <h1 className="text-xl md:text-2xl font-bold">دارا رایا تک</h1>
              <p className="text-xs md:text-sm ">شرکت نرم افزاری</p>
            </div>
          </div>
        )}
      </div>
      <div className="hidden lg:block border-r border-gray-500 h-8 z-50"></div>
      {/* 🟨🟨🟨 UI For ==> Menu */}
      <div className="flex justify-center items-center w-1/3">
        {eng ? (
          <nav className="hidden lg:block space-x-6 w-56 text-center">
            <Link to={"/"}>Home</Link>
            <Link to={"/about"}>About</Link>
            <Link to={"/blog"}>Blog</Link>
          </nav>
        ) : (
          <nav className="hidden lg:block space-x-6 w-56 text-center font-fa">
            <div className="flex justify-center items-center gap-8">
              <Link to={"/blog"}>بلاگ</Link>
              <Link to={"/about"}>درباره ما</Link>
              <Link to={"/"}>خانه</Link>
            </div>
          </nav>
        )}

        {/* 🟨🟨🟨 UI For ==> Theme Toggling */}
        <div className="flex items-center gap-4 relative z-40 ">
          <div
            className="bg-gray-200 rounded-2xl w-14 h-8 relative p-1 cursor-pointer dark:bg-[#4D398A]"
            onClick={handleTheme}
          >
            <i
              className={`fas ${
                themeToggling
                  ? "fa-sun right-1 rotate-[360deg] text-white bg-gray-400"
                  : "fa-moon bg-black text-white"
              } rounded-full px-1 text-base absolute transform ease-out duration-1000`}
            ></i>
          </div>

          <div className="border-[5px] border-gray-200 dark:border-[#4B3982] rounded-full cursor-pointer">
            <img
              onClick={handleLang}
              src={eng ? images.USA_Logo : images.IRN_Logo}
              alt=""
              className="w-8 "
            />
          </div>

          <div
            className="block lg:hidden dark:brightness-200 w-4 mr-1 z-50"
            onClick={handleMenu}
          >
            {!menuToggling ? (
              themeToggling ? (
                <MenuBar color="#fff" />
              ) : (
                <MenuBar color="#000" />
              )
            ) : (
              <i className="fa-solid fa-xmark pl-1 text-2xl"></i>
            )}
          </div>
        </div>
      </div>
      <div className="hidden lg:block border-r border-gray-500 h-8 z-50"></div>

      {/* 🟨🟨🟨 UI For ==> User Login */}
      <Login eng={eng} />

      {/* 🟨🟨🟨 UI Only ==> For Mobile Menu */}
      {eng
        ? menuToggling && (
            <nav className="bg-gray-300 dark:bg-[#1e0a5f] dark:text-gray-200 absolute top-0 left-0 right-0 flex flex-col items-center justify-center p-2 z-50 origin-top transform duration-200 ease-in rounded-b-3xl">
              <Link
                to={"/"}
                onClick={() => setMenuToggling(false)}
                className="w-full py-2 text-center"
              >
                Home
              </Link>
              <Link
                to={"/about"}
                onClick={() => setMenuToggling(false)}
                className="w-full py-2 text-center"
              >
                About
              </Link>
              <Link
                to={"/Blog"}
                onClick={() => setMenuToggling(false)}
                className="w-full py-2 text-center"
              >
                Blog
              </Link>
            </nav>
          )
        : menuToggling && (
            <nav className="bg-gray-300 dark:bg-[#1e0a5f] dark:text-gray-200 absolute top-0 left-0 right-0 flex flex-col items-center justify-center p-2 z-50 origin-top transform duration-200 ease-in rounded-b-3xl">
              <Link
                to={"/"}
                onClick={() => setMenuToggling(false)}
                className="w-full py-2 text-center"
              >
                خانه
              </Link>
              <Link
                to={"/about"}
                onClick={() => setMenuToggling(false)}
                className="w-full py-2 text-center"
              >
                درباره ما
              </Link>
              <Link
                to={"/Blog"}
                onClick={() => setMenuToggling(false)}
                className="w-full py-2 text-center"
              >
                بلاگ
              </Link>
            </nav>
          )}
    </header>
  );
};

export default Header;
