import ReactDOM from "react-dom/client";
import React from "react";
import App from "./App";
import "./style/index.css";
import { Auth0Provider } from "@auth0/auth0-react";
import { ConsentProvider } from "react-hook-consent";

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Auth0Provider
    domain={domain}
    clientId={clientId}
    authorizationParams={{
      redirect_uri: window.location.origin,
    }}
  >
    <ConsentProvider
      options={{
        services: [
          {
            id: "1",
            name: "Full Name",
            scripts: [
              { id: "external-script", src: "https://myscript.com/script.js" },
              { id: "inline-code" },
            ],
            cookies: [{ pattern: "cookie-name" }],
            mandatory: true,
          },
          {
            id: "2",
            name: "Google ID",
            scripts: [
              { id: "external-script", src: "https://myscript.com/script.js" },
              { id: "inline-code" },
            ],
            cookies: [{ pattern: "cookie-name" }],
            mandatory: true,
          },
        ],
        theme: "light",
      }}
    >
      <App />
    </ConsentProvider>
  </Auth0Provider>
);
