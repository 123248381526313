import React from "react";
import { Helmet } from "react-helmet";
import BackToUp from "@uiw/react-back-to-top";
import { FaArrowUp } from "react-icons/fa";
import images from "../constants/images";
import Circles from "./Circles";

export const Blog = ({ themeToggling, eng }) => {
  return (
    <>
      <Helmet>
        <title>Dara Raya Tech | Blog</title>
        <meta
          name="We design & deploy your Website."
          content="Dara Raya Tech"
        />
      </Helmet>
      <section className="px-4 lg:px-36 py-12 text-center dark:bg-[#200A4C] text-gray-700 dark:text-gray-200 relative ">
        <div className="absolute top-0 left-0 right-0 bottom-0  h-full w-full dark:bg-[#2B1867] lightRound xl:lightRoundBig"></div>

        <Circles themeToggling={themeToggling} />

        {eng ? (
          <p className="relative pt-8 w-full lg:w-[650px] mx-auto ">
            Blog section is under construction
          </p>
        ) : (
          <p className="relative pt-8 w-full lg:w-[650px] mx-auto font-fa ">
            بلاگ در حال ساخت می باشد
          </p>
        )}

        {/* Mobile Image UI Section */}
        <div className="relative flex items-center justify-center">
          {themeToggling ? (
            <img src={images.Construction} alt="" className="w-8/12" />
          ) : (
            <img src={images.Construction} alt="" className="w-8/12" />
          )}
        </div>
      </section>
      <BackToUp>
        <FaArrowUp />
      </BackToUp>
    </>
  );
};
