import React from "react";

function Loading() {
  return (
    <>
      <div className="flex justify-center items-center h-full bg-transparent">
        <div className="flex items-center justify-center ">
          <div className="w-5 h-5 border-b-2 border-gray-600 rounded-full animate-spin"></div>
        </div>
      </div>
    </>
  );
}

export default Loading;
