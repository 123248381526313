import React from "react";
import { Helmet } from "react-helmet";
import BackToUp from "@uiw/react-back-to-top";
import { FaArrowUp } from "react-icons/fa";
// import images from "../constants/images";
import Circles from "./Circles";

export const About = ({ themeToggling, eng }) => {
  return (
    <>
      <Helmet>
        <title>Dara Raya Tech | About</title>
        <meta
          name="We design & deploy your Website."
          content="Dara Raya Tech"
        />
      </Helmet>
      <section className="px-4 lg:px-36 py-12 text-center dark:bg-[#200A4C] text-gray-700 dark:text-gray-200 relative pointer-events-none">
        <div className="absolute top-0 left-0 right-0 bottom-0  h-full w-full dark:bg-[#2B1867] lightRound xl:lightRoundBig"></div>

        <Circles themeToggling={themeToggling} />

        <div className="lg:flex font-fa">
          {eng ? (
            <h1 className="relative font-bold text-[28px] lg:pt-24 lg:pl-24 md:text-4xl lg:text-5xl lg:leading-[60px]">
              About Us
            </h1>
          ) : (
            <h1 className="relative font-bold text-[28px] lg:pt-24 lg:pl-24 md:text-4xl lg:text-5xl lg:leading-[60px]">
              درباره ما
            </h1>
          )}

          {eng ? (
            <p className="relative pt-8 w-full lg:w-[650px] mx-auto text-justify">
              <p className="pb-4">
                Established in 2023, Dara Raya Tech is driven by a mission to
                redefine the business landscape, empowering customers to harness
                the showcasing power of their products. We specialize in
                building exceptional UI/UX designs for demanding organizations
                and people, developing web applications, and utilizing
                cutting-edge web development technologies.
              </p>
              <p className="pb-4">
                Our growth is fuelled by our dedication to client success and
                relentless pursuit of innovation. Our founding philosophy is
                simple: the cornerstone of any successful organization is its
                team. We help businesses unlock their potential by crafting
                bespoke teams that stimulate growth, inspire creativity, and
                boost productivity. We tailor our team-building services to the
                unique needs of our clients, ensuring they get top-tier talent
                and the most effective strategies to manage and nurture their
                workforce.
              </p>
              <p className="pb-4">
                Our expertise extends to the development of websites and web
                applications. Leveraging the latest technologies, we design and
                build innovative websites that streamline operations, enhance
                performance, and catalyze growth. Whether you need an eCommerce
                website or a dynamic company portfolio, we have the resources
                and expertise to deliver tailored solutions that align with your
                specific needs. Our suite of digital services aims to bring
                businesses closer to their customers.
              </p>
              <p className="pb-4">
                Our UI/UX experts and developers utilize their deep
                understanding of consumer behavior, end-user demands, market
                trends, and digital technologies to devise strategies and
                solutions that enhance user engagement, optimize user
                experience, and elevate brand visibility. Our core success lies
                in our growth mindset, our commitment to client satisfaction,
                and our adaptability to an ever-changing business environment.
                Our strength is our team - a diverse group of professionals
                passionate about helping businesses thrive in today's complex
                marketplace.
              </p>
              <p className="pb-4">
                We are tirelessly committed to staying on top of technological
                advancements and industry trends, embodying a spirit of
                perpetual learning and adaptation. This makes us a dynamic
                partner for businesses seeking to utilize the power of
                well-rounded teams and digital technology to scale operations,
                improve performance, and secure a competitive edge. As we
                continue to grow and evolve, we remain faithful to our core
                values of integrity, excellence, and innovation, consistently
                delivering top-tier services that aid our clients in achieving
                their business objectives.
              </p>
              <p className="pb-4">
                Welcome to Dara Raya Tech - the home of synergistic team
                building and advanced technological solutions, crafted for your
                business success.
              </p>
            </p>
          ) : (
            <p className="relative pt-8 w-full lg:w-[650px] mx-auto text-right text-lg">
              <p className="pb-4">
                شرکت دارا رایا تک در سال ۲۰۲۳ و با هدف ایجاد تحول در زمینه طراحی
                وبسایت و وب اپلیکیشن های مورد نیاز کسب و کار ها تأسیس شد. تبحر
                ما در طراحی و پیاده سازی رابط کاربری آسان برای شرکت ها و اشخاص
                متقاضی با ایجاد وب اپلیکیشن هایی با تلنولوژي روز دنیا می باشد
              </p>
              <p className="pb-4">
                پیشرفت ما از طریق تعهد به موفقیت مشتریان و دنبال کردن نوآوری های
                روز دنیا شکل می گیرد. فلسفه تأسیس ما ساده است: پیشرفت هر شرکت به
                کارمندان ماهر آن بر می گردد. ما به کسب و کار های نوپا کمک می
                کنیم پتانسیل خود را توسط خلاقیت تیم ما به مشتریان خود نشان دهند.
                تیم طراحی و توسعه دهنده ما نیاز شما را برآورد کرده و با استفاده
                از تکنولوژی های به روز برنامه نویسی وب پروژه شما را بر روی
                اینترنت در دسترس عموم قرار می دهد
              </p>
              <p className="pb-4">
                تخصص ما طراحی و ساخت وبسایت و وب اپلیکیشن می باشد. استفاده از
                زبان های برنامه نویسی فرانت اند و بک اند به روز باعث می شود
                تجربه کاربر در استفاده از سایت ساخته شده توسط تیم ما بدون اشکال
                باشد. اگر سایت شما برای فروش محصولاتتان یا حتی یک رزومه داینامیک
                باشد، ما توانایی طراحی و اجرای آن را طبق نیاز شما داریم. هدف ما
                نزدیک کردن مشتریان به بیزینس شما می باشد
              </p>
              <p className="pb-4">
                طراحان یو آی و یو ایکس ما برای برآورد نیاز شما، مشتریان شما،
                روند بازار و انتخاب تکنولوژي مورد نیاز جهت پیاده سازی خواسته شما
                و بولد کردن برند شما آموزش دیده اند. موفقیت ما در موفقیت و رضایت
                مشتریان ما و همچنین در سازگار شدن تیم ما با متد های روز برنامه
                نویسی دنیا نهفته است. تیم ما متشکل از برنامه نویسان و طراحان
                ماهر و علاقه مند به این رشته است
              </p>
              <p className="pb-4">
                ما همواره برای همسو بودن با نیازها و پیشرفت های روز برنامه نویسی
                دنیا تلاش می کنیم و همین امر باعث ماندن ما در مارکت طراحان
                وبسایت و اعتماد کسب و کارهای داخلی شده است. سعی ما بر این است که
                به فلسفه تأسیس خود پایبند مانده و قدم در راه نوآوری برداریم تا
                ابزار پیشرفت مشتریان خود را فراهم کنیم
              </p>
              <p className="pb-4">
                دارا رایا تک - راه پیشرفت و موفقیت کسب و کار ها به وسیله هم
                افزایی
              </p>
            </p>
          )}
        </div>
      </section>
      <BackToUp>
        <FaArrowUp />
      </BackToUp>
    </>
  );
};
