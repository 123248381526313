import { Link } from "react-router-dom";

const Footer = ({ eng }) => {
  return (
    <footer className="px-4 lg:px-36 dark:bg-[#200A4C] dark:text-gray-300 py-10 pt-28">
      <div className="border-t border-gray-500 flex justify-between flex-wrap gap-4 pt-10">
        <div className="flex-auto space-y-4 max-w-fit pointer-events-none">
          <h2 className="text-2xl lg:text-3xl font-bold tracking-wider text-[#545454] dark:text-white">
            {eng ? (
              <div>
                <span className="text-[#B322F1]">D</span> R T C O .
              </div>
            ) : (
              <div className="space-x-2 text-right font-fa">
                <span className="text-[#B322F1]">د</span>ارا
                <span></span>رایا
                <span></span>تک
              </div>
            )}
          </h2>
          {eng ? (
            <p className="w-full lg:w-96 font-light dark:text-slate-400 ">
              Dara Raya Tech Co. Ltd. is a privately owned software and web
              development company based in Tehran, Iran
              <nav className="pointer-events-auto font-bold text-red-600">
                <Link to={"/privacy"}>Privacy Policy</Link>
              </nav>
            </p>
          ) : (
            <p className="w-full lg:w-96 font-light dark:text-slate-400 font-fa text-right">
              دارا رایا تک یک شرکت خصوصی ثبت شده در ایران است که در زمینه نرم
              افزار و طراحی وبسایت فعالیت می کند
              <nav className="pointer-events-auto font-bold text-red-600">
                <Link to={"/privacy"}>حریم خصوصی</Link>
              </nav>
            </p>
          )}

          <div className="pt-4 lg:pt-6 space-x-4 flex">
            <a href="https://www.linkedin.com/company/98378688/">
              <i className="iconStyling fab fa-linkedin pointer-events-auto"></i>
            </a>

            {/* <i className="iconStyling fab fa-instagram"></i> */}
          </div>
        </div>

        <div className="flex gap-16 lg:gap-10 pointer-events-none">
          <div className="flex flex-col gap-6">
            <p className="font-bold text-xl pb-1 lg:pb-4">Frontend</p>
            <i>React.js</i>
            <i>Next.js</i>
            <i>Javascript</i>
          </div>
          <div className="flex flex-col gap-6">
            <p className="font-bold text-xl pb-1 lg:pb-4">Backend</p>
            <i>Node.js</i>
            <i>Express</i>
            <i>MongoDB</i>
          </div>
        </div>

        <div className="flex flex-col justify-start items-start">
          {/* <p className="font-bold text-xl pb-4">Download App</p>
          <div className="flex items-center gap-4 pb-4">
            <img src={images.FooterApple} alt="" className="w-36" />
            <img src={images.FooterGPlay} alt="" className="w-36" />
          </div> */}
          {eng ? (
            <p className="font-bold text-xl pb-4 lg:pb-6 pointer-events-none">
              Contact
            </p>
          ) : (
            <p className="font-bold text-xl pb-4 lg:pb-6 font-fa pointer-events-none">
              اطلاعات تماس
            </p>
          )}
          <div className="space-y-4">
            <p>
              <i className="text-[#B322F1] pr-4 fas fa-phone"></i>
              +98 21 88624650
              {/* &nbsp; | &nbsp;+98 912 3782309 */}
            </p>
            <p>
              <i className="text-[#B322F1] pr-4 fas fa-paper-plane"></i>
              info@drtco.ir
            </p>
            <p>
              <i className="text-[#B322F1] pr-4 fas fa-location-dot text-wrap"></i>
              No. 115, Akbari St., Yusef Abad
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
