import React from "react";
import Loading from "../components/Loading";
import { useAuth0 } from "@auth0/auth0-react";

const Login = ({ eng }) => {
  const { isLoading, error, loginWithRedirect, logout, user, isAuthenticated } =
    useAuth0();

  return (
    <div className="flex justify-end items-center w-1/12">
      {eng ? (
        <div className="hidden lg:flex items-center gap-4">
          <div className="cursor-pointer">
            {error && (
              <div className="flex items-end justify-end w-max">
                Authentication Error
              </div>
            )}
            {!error && isLoading && (
              <div className="flex items-end justify-end w-max">
                <Loading />
              </div>
            )}
            {!error && !isLoading && (
              <div>
                {!isAuthenticated && (
                  <button
                    className="flex items-end justify-end w-max"
                    onClick={() => {
                      loginWithRedirect();
                    }}
                  >
                    Sign In
                  </button>
                )}

                {isAuthenticated && (
                  <button
                    className="flex items-end justify-end w-max"
                    onClick={() =>
                      logout({
                        logoutParams: { returnTo: window.location.origin },
                      })
                    }
                  >
                    Sign Out
                  </button>
                )}
              </div>
            )}
          </div>

          <div className="w-max">
            {isAuthenticated ? (
              <article>
                {user?.picture && (
                  <img
                    src={user.picture}
                    alt={user?.name}
                    className="rounded-full h-10 w-10 overflow-hidden"
                  ></img>
                )}
                {/* {user?.name && <h2>{user?.name}</h2>} */}
                {/* <ul>
                {Object.keys(user).map((objKey, i) => (
                  <li key={i}>
                    {objKey}:{user[objKey]}
                  </li>
                ))}
              </ul> */}
              </article>
            ) : (
              <i className="fas fas fa-user-alt pointer-events-none"></i>
            )}
          </div>

          {/* <span className="pointer-events-none">/</span>
          <p className="cursor-pointer">Signup</p> */}
        </div>
      ) : (
        <div className="hidden lg:flex items-center gap-4 font-fa">
          <div className="cursor-pointer">
            {error && (
              <div className="flex items-end justify-end w-max">
                اشکال در ورود
              </div>
            )}
            {!error && isLoading && (
              <div className="flex items-end justify-end w-max">
                <Loading />
              </div>
            )}
            {!error && !isLoading && (
              <div>
                {!isAuthenticated && (
                  <button
                    className="flex items-end justify-end w-max"
                    onClick={() => {
                      loginWithRedirect();
                    }}
                  >
                    ورود
                  </button>
                )}

                {isAuthenticated && (
                  <button
                    className="flex items-end justify-end w-max"
                    onClick={() => {
                      logout();
                    }}
                  >
                    خروج
                  </button>
                )}
              </div>
            )}
          </div>

          <div className="w-max">
            {isAuthenticated ? (
              <article>
                {user?.picture && (
                  <img
                    src={user.picture}
                    alt={user?.name}
                    className="rounded-full h-10 w-10 overflow-hidden"
                  ></img>
                )}
                {/* {user?.name && <h2>{user?.name}</h2>} */}
                {/* <ul>
                {Object.keys(user).map((objKey, i) => (
                  <li key={i}>
                    {objKey}:{user[objKey]}
                  </li>
                ))}
              </ul> */}
              </article>
            ) : (
              <i className="fas fas fa-user-alt pointer-events-none"></i>
            )}
          </div>

          {/* <span className="pointer-events-none">/</span>
          <p className="cursor-pointer">ورود</p> */}
        </div>
      )}
    </div>
  );
};

export default Login;
